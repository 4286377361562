<!-- 登录页面 -->
<template>
  <div>
    <div class="login_wrapper">
      <div class="left">
        <img src="../../assets/-s-login.png" alt="" />
      </div>
      <div class="right">
        <div class="right_title">登&nbsp;&nbsp;&nbsp;录</div>
        <div class="right_content">
          <el-form ref="loginForm" :model="loginForm" :rules="loginRules">
            <el-form-item class="right_content_item" prop="name">
              <!-- <i class="iconfont icon-kongxinduigou login_name_icon"></i> -->

              <svg class="icon login_name_icon" aria-hidden="true">
                <use xlink:href="#icon-zhanghao-03"></use>
              </svg>

              <el-input v-model="loginForm.name" placeholder="请输入您的账号"></el-input>
              <div class="login_name_tip" v-show="login_name_undefined">
                账号名不存在
              </div>
            </el-form-item>
            <el-form-item class="right_content_item" prop="pwd">
              <svg class="icon login_name_icon" aria-hidden="true">
                <use xlink:href="#icon-mima-04"></use>
              </svg>

              <el-input v-model="loginForm.pwd" type="password" placeholder="请输入您的密码"></el-input>
              <div class="login_name_tip" v-show="login_password_undefined">
                密码错误
              </div>
            </el-form-item>
            <el-form-item class="right_content_code_item" prop="code">
              <i class="iconfont icon-kongxinduigou login_name_icon"></i>
              <el-input v-model="loginForm.code" placeholder="请输入您的验证码"></el-input>
              <div class="login_name_tip" v-show="login_code_undefined">
                验证码错误
              </div>
              <div class="login_code_box" @click="handleClickChangeLoginCode">
                <img :src="QrImg" />
              </div>
            </el-form-item>
            <el-form-item class="right_content_login_item" style="margin-bottom: 0">
              <el-button class="right_content_login_btn" @click="hanleClickLogin">登&nbsp;&nbsp;&nbsp;录</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCode, Login } from "../../api/api.js";
import { mapMutations } from "vuex";
export default {
  name: "login",
  data() {
    return {
      loginForm: {
        name: "",
        pwd: "",
        code: "",
      },
      loginRules: {
        name: [{ required: true, message: "请输入您的账号", trigger: "blur" }],
        pwd: [{ required: true, message: "请输入您的密码", trigger: "blur" }],
        code: [
          { required: true, message: "请输入您的验证码", trigger: "blur" },
        ],
      },
      // 存到token的值
      userInfo: {
        username: "",
        password: "",
        identity: "",
      },
      // 二维码图片链接
      QrImg: "",
      // 二维码的唯一标识,给后端判断的
      uniqid: "",
      // 账号名错误提示
      login_name_undefined: false,
      // 密码错误提示
      login_password_undefined: false,
      // 验证码错误提示
      login_code_undefined: false,
    };
  },
  created() {
    document.title = '质量提升工程网站后台'
    this.getQrCode();
  },
  // mounted() {
  //     // 按下回车验证登录
  //     document.onkeydown = () => {
  //     var key = window.event.keyCode
  //     if (key === 13) {
  //         this.hanleClickLogin()
  //     }
  //     }
  // },
  methods: {
    ...mapMutations(["changeMenuId"]),
    // 点击登录的事件
    hanleClickLogin() {
      // this.$router.push("/adminschool");

      Login(
        this.loginForm.name,
        this.loginForm.pwd,
        this.loginForm.code,
        this.uniqid
      ).then((res) => {
        console.log(res.data);

        if (res.data.code == 200) {
          this.$message({
            message: res.data.msg,
            type: "success",
          });
          localStorage.setItem("token", JSON.stringify(res.data.data));
          sessionStorage.setItem("userId", res.data.data.id);
          sessionStorage.setItem("tokened", res.data.data.token);

          if (res.data.data.identity == 1) {

            this.$router.push("/adminschool");
          } else {
            this.$router.push({
              path: "/adminproject",
              query: {
                id: res.data.data.zhuanlan_id,
                name: res.data.data.username,
              },
            });
          }
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
          this.getQrCode();
        }
        // if(res.c)
        // let userData = res.data;
        // let { id } = userData;
        // window.sessionStorage.setItem("userId", id);
        // if (userData.code == 0) {
        //   this.$message({
        //     type: "success",
        //     message: "登录成功",
        //   });
        //   this.changeMenuId("1");
        //   for (let key in userData) {
        //     if (key == "username") {
        //       this.userInfo.username = userData[key];
        //     } else if (key == "pwd") {
        //       this.userInfo.password = userData[key];
        //     } else if (key == "identity") {
        //       this.userInfo.identity = userData[key];
        //     }
        //   }
        //   window.sessionStorage.setItem(
        //     "userInfo",
        //     JSON.stringify(this.userInfo)
        //   );

        //   if (res.data.identity == 2) {
        //     this.$router.push("/Admin"); //登陆超级管理员
        //   } else {
        //     this.$router.push("/home");
        //   }
        // } else if (userData.code == 1) {
        //   if (userData.msg == "账号或密码错误") {
        //     this.getQrCode();
        //   } else if (userData.msg == "验证码错误") {
        //     this.login_code_undefined = true;
        //     this.getQrCode();
        //   } else if (userData.msg == "密码错误") {
        //     this.login_password_undefined = true;
        //     this.getQrCode();
        //   } else if (userData.msg == "账号不存在") {
        //     this.login_name_undefined = true;
        //     this.getQrCode();
        //   }
        // }
      });
    },
    // 获取二维码接口
    getQrCode() {
      getCode().then((res) => {
        this.QrImg = res.data.content;
        this.uniqid = res.data.uniqid;
      });
    },
    // 点击更换验证码
    handleClickChangeLoginCode() {
      this.getQrCode();
    },
    // 跳转到注册页面
    // hanlePushResiter() {
    //   this.$router.push('/register')
    // }
  },
};
</script>

<style scoped lang="scss">
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.login_wrapper {
  width: 1360px;
  height: 565px;
  background: #ffffff;
  border-radius: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  transform: translate(-50%, -50%);

  .left {
    width: 635px;
    height: 604px;
    border-radius: 10px;
    margin-top: -20px;
    margin-left: 58px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .right {
    width: 667px;

    .right_title {
      width: 100%;
      text-align: center;
      margin-top: 39px;
      font-size: 36px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #266eff;
      margin-bottom: 70px;
    }

    .right_content {
      width: 449px;
      margin: 0 auto;

      .right_content_item {
        position: relative;

        .login_name_icon {
          position: absolute;
          left: 0;
          top: 13px;
          z-index: 2;
        }

        .login_name_tip {
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ff0000;
          margin-left: 10px;
          line-height: unset;
          position: absolute;
          top: 51px;
          left: 0;
        }
      }

      .right_content_code_item {
        margin-top: 40px;
        position: relative;

        .login_name_icon {
          position: absolute;
          left: 0;
          top: 0;
          z-index: 2;
        }

        .login_name_tip {
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ff0000;
          margin-left: 10px;
          line-height: unset;
          position: absolute;
          top: 51px;
          left: 0;
        }

        .login_code_box {
          position: absolute;
          right: 0;
          top: -22px;
          cursor: pointer;
        }
      }

      .right_content_login_btn {
        margin-top: 60px;
        width: 449px;
        height: 70px;
        background: #266eff;
        border-radius: 35px;
        font-size: 30px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        line-height: 27px;
      }
    }
  }
}
</style>
